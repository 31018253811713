import * as React from 'react';

import './LiveLineup.scss'
import { PlayerCard } from './PlayerCard';
import { EventTeamSquad, EventTeamLineup, EventPlayer } from '../../../../../../shared/event-api/model';
import { TickerTeam } from '../../../models';

interface Props {
  lineup: EventTeamLineup;
  teamSquad: EventTeamSquad;
  team: TickerTeam;
  side: string;
  isServing: boolean;
}

export const LiveLineup = (props: Props) => {

  const { lineup, teamSquad, team, side, isServing } = props
  const players = lineup.playerUuids.map(uuid => teamSquad.players.find(p => p.uuid === uuid) as EventPlayer);
  const bench = teamSquad.players.filter(p => lineup.playerUuids.indexOf(p.uuid) < 0);
  const defaultLibero = teamSquad.players.find(p => p.uuid === lineup.defaultLiberoUuid);
  const trainer = teamSquad.officials.find(o => o.function === 'TRAINER');

  const isCaptain = (p: EventPlayer) => teamSquad.captain != null && p.uuid === teamSquad.captain.uuid;
  const isLibero = (p: EventPlayer) => teamSquad.liberos.find(l => p.uuid === l.uuid) != null;
  const isDefaultLibero = (p: EventPlayer) => !!defaultLibero && (defaultLibero.uuid === p.uuid)

  return <div className={'LiveLineup ' + side}>
    <div className="bench">
      {bench.map((p, index) =>
        <div key={p.uuid}>
          <PlayerCard isServing={false} index={index} player={p} libero={isLibero(p)} defaultLibero={isDefaultLibero(p)} captain={isCaptain(p)} />
        </div>
      )}
    </div>
    {trainer != null && <div className="trainer"><b>Trainer</b> {trainer.firstName} {trainer.lastName}</div>}
    <div className='inner'>
      <h1>{team.name}</h1>
      <div className="field">
        {players.map((p, index) =>
          <div key={p.uuid} className={'pos-' + (index + 1)}>
            <PlayerCard player={p} isServing={isServing} index={index} libero={isLibero(p)} defaultLibero={isDefaultLibero(p)} captain={isCaptain(p)} />
          </div>
        )}
      </div>
    </div>
  </div>
}
