const SET_MATCH_DETAIL_VIEW = '[MatchDetailView] set view'

export enum MatchDetailView {
  "LIVE_TICKER"="LIVE_TICKER",
  "MATCH_STATS"="MATCH_STATS",
  "LINEUP"="LINEUP",
  "REFEREES"="REFEREES",
  "RANKINGS"="RANKINGS"
}

export const setMatchDetailView = (view: MatchDetailView): SetMatchDetailView => ({ type: SET_MATCH_DETAIL_VIEW, payload: view })

interface SetMatchDetailView {
  readonly type: typeof SET_MATCH_DETAIL_VIEW
  payload: MatchDetailView
}

interface MatchDetailViewState {
  view: MatchDetailView
}

const initialMultiFilterState: MatchDetailViewState = {
  view: MatchDetailView.LIVE_TICKER,
}

export const matchDetailViewReducer = (state: MatchDetailViewState = initialMultiFilterState, action: MatchDetailViewAction): MatchDetailViewState => {
  switch(action.type) {
    case SET_MATCH_DETAIL_VIEW:
      return {
        ...state,
        view: action.payload
      }
   default:
      return state
  }
}

export type MatchDetailViewAction = SetMatchDetailView
