import * as React from "react";

import './MatchCardBody.scss';
import { Scores } from '../../../match/Scores';
import { TickerMatchState, TickerMatch } from '../../../models';

interface Props {
  matchState: TickerMatchState
  match: TickerMatch
}

export const MatchCardBody = (props: Props) => {

  const {
    matchState,
    match
  } = props

  const { indefinitelyRescheduled, delayPossible } = match

  return (
    <div className="MatchCardBody">
      {!matchState || !matchState.started
        ? <div>
          {delayPossible && !indefinitelyRescheduled && <div className="info-text">Der Spielbeginn kann sich durch vorher stattfindende Veranstaltungen verzögern.</div>}
          {indefinitelyRescheduled && <div className="info-text">Das Spieldatum steht zum jetzigen Zeitpunkt noch nicht fest.</div>}
        </div>
        : <Scores {...matchState} />}
    </div>
  )
}
