import * as React from "react";
import { connect } from "react-redux";
import { ScrollData } from "../match-filter/MatchFilter";
import "./DayFilter.scss";

interface Props {
  focused: boolean
  selected: boolean
  idx: number
  formattedDay: string
  applyFilter: (idx: number, scrollData: ScrollData) => void,
  applyScroll: (idx: number, scrollData: ScrollData) => void
}

class DayFilter extends React.PureComponent<Props> {

  private ref = React.createRef<HTMLDivElement>()

  public componentDidMount() {
    if (this.props.selected) {
      this.applyFilter()
    }
  }

  componentDidUpdate() {
    if (this.props.selected) {
      const dayFilter = this.ref.current!
      const left = dayFilter.getBoundingClientRect().left
      const clientWidth = dayFilter.clientWidth
      this.props.applyScroll(this.props.idx, { left, clientWidth })
    }
  }

  applyFilter = () => {
    const dayFilter = this.ref.current!
    const left = dayFilter.getBoundingClientRect().left
    const clientWidth = dayFilter.clientWidth
    this.props.applyFilter(this.props.idx, { left, clientWidth })
  }

  render() {
    return (
      <div
        className={'day-filter ' + (this.props.focused ? 'active' : '')}
        onClick={this.applyFilter}
        ref={this.ref}>
        {this.props.formattedDay}
      </div>
    )
  }

}

export default connect()(DayFilter)
