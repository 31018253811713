import * as React from "react";
import './TeamLogo.scss';
import { TickerTeam } from "../models";
import { TeamSide } from "../../../../shared/interfaces/models";
import { useEffect, useRef, useState } from "react";

interface Props {
  team: TickerTeam
  side: TeamSide
  rescheduled?: boolean
}

export const TeamLogo = React.memo((props: Props) => {
  const { team, side, rescheduled } = props;
  const { logoImage200, name, letter } = team;

  const [isVisible, setIsVisible] = useState(false);
  const logoRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (logoRef.current) {
      observer.observe(logoRef.current);
    }

    return () => {
      if (logoRef.current) {
        observer.unobserve(logoRef.current);
      }
    };
  }, []);

  const style = { backgroundImage: isVisible && logoImage200 ? `url(${logoImage200})` : '' };

  return (
    <div className={`TeamLogo ${side}`} ref={logoRef}>
      <div className="TeamLogo-image">
        {isVisible && logoImage200 ? (
          <div className={`LogoImage${rescheduled ? ' rescheduled' : ''}`} style={style}></div>
        ) : (
          <div className="AvatarLogo">{letter}</div>
        )}
      </div>
      <div className={`TeamLogo-name${rescheduled ? ' rescheduled' : ''}`}>{name}</div>
    </div>
  );
});
