import * as React from "react";

import './MatchCardHeader.scss';
import { CurrentSetScore } from "./CurrentSetScore";
import { TeamLogo } from '../../../match/TeamLogo';
import { MatchDate } from '../../../match/MatchDate';
import { TickerMatchState, TickerMatch, TickerMatchSeries } from '../../../models';

interface Props {
  match: TickerMatch
  matchState: TickerMatchState
  matchSeries: TickerMatchSeries | undefined
}


export const MatchCardHeader = (props: Props) => {

  const formatTeamDescription = (teamDescription: string) => {
    let result = teamDescription.split(' ')
    const matchNumber = result.splice(-1)
    return result.join(' ') + '\n' + matchNumber
  }

    const {
      matchState,
      match,
      matchSeries
    } = props

    const { team1, team2, indefinitelyRescheduled } = match

    return (
      <div className="MatchCardHeader">
        {team1 ? <TeamLogo rescheduled={indefinitelyRescheduled} side={"leftTeam"} team={team1} /> : <div className="Team-description">{formatTeamDescription(match.teamDescription1)}</div>}
        <div className={"Match-info" + (indefinitelyRescheduled ? " rescheduled" : "")}>
          <div className="Match-series-name">{matchSeries && matchSeries.name}</div>
          <MatchDate match={match} />
          { !matchState || !matchState.started
              ? (indefinitelyRescheduled ? <div className="rescheduled-info">Termin folgt.</div> : <div className="Match-time">{match.formattedTime + ' UHR'}</div>)
              : <CurrentSetScore matchState={matchState} />
          }
        </div>
        {team2 ? <TeamLogo rescheduled={indefinitelyRescheduled} side={"rightTeam"} team={team2} /> : <div className="Team-description">{formatTeamDescription(match.teamDescription2)}</div>}
      </div>
    )
}
