import * as React from 'react'
import { DetailedTickerMatchState, Status } from '../../../models';
import FavouritesButton from '../../../match/favourite/FavouritesButton';
import { TickerMatchState } from '../../../models';
import { TickerMode } from '../../../../../shared/models/ticker';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './MatchFooter.scss';
import { FavoritesState } from '../../../match/favourite/reducer';

interface Props {
  status: Status
  matchUuid: string
  matchState?: TickerMatchState
  scoreSheetPdfUrl?: string
  favorites?: FavoritesState
  mode: TickerMode
}

export const MatchFooter = React.memo((props: Props) =>{
  const { status, matchUuid, matchState, scoreSheetPdfUrl, mode, favorites } = props
  switch(status) {
    case Status.POST:
      return mode === 'DETAILED'
        ? <a className="ScoresheetLink"
            href={matchState ? (matchState as DetailedTickerMatchState).scoresheetPdfUrl : scoreSheetPdfUrl}
            rel="noopener noreferrer"
            target="_blank"><FontAwesomeIcon icon={faFilePdf}/>Spielbericht</a>
        : <span></span>
    case Status.LIVE:
    case Status.PRE:
      return favorites ? <FavouritesButton favorites={favorites} matchUuid={matchUuid} /> : <span></span>
  }
})
