
import * as React from 'react';
import { ScrollData } from '../../../utils';

import './MatchDetailMenu.scss';
import { MatchDetailView, setMatchDetailView } from './reducer';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useRef } from 'react';
import { TickerMatchSeries } from '../../../models/index';
import MatchDetailMenuItem from './MatchDetailMenuItem';
import { AssociationRootState } from '../../..';

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  setMatchDetailView
}, dispatch)

const mapStateToProps = (state: AssociationRootState) => {
  return {
    matchDetailView: state.matchDetailView
  }
}

interface OwnProps {
  liveStatisticsEnabled: boolean
  matchSeries: TickerMatchSeries
  matchDetailViews: any[]
}
type Props = OwnProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const MatchDetailMenu = (props: Props) => {

  const matchFilterRef: any = useRef(null)
  let initialScrollData: ScrollData
  let currentFilterScrollSelectTimeout: NodeJS.Timeout;

  const activeView = props.matchDetailView.view
  const matchDetailViews = props.matchDetailViews

  const scrollToDayFilter = (scrollData: ScrollData) => {
    const container = matchFilterRef.current
    if (!container || !scrollData) return
    const containerLeft = container.getBoundingClientRect().left
    const selectionLeft = scrollData.left - containerLeft
    container.scrollLeft += selectionLeft - container.clientWidth / 2 + scrollData.clientWidth / 2
    container.style.scrollBehavior = "smooth"
  }

  setTimeout(() => {
    scrollToDayFilter(initialScrollData)
  }, 100)

  const applyView = (view: MatchDetailView, scrollData: ScrollData) => {
    if (!initialScrollData) {
      initialScrollData = scrollData
    }
    if (view !== activeView) {
      props.setMatchDetailView(view)
    }
    scrollToDayFilter(scrollData)
  }


  const applyScroll = (view: MatchDetailView, scrollData: ScrollData) => {
    scrollToDayFilter(scrollData)
  }

  const selectMatchFilterByElement = (a: HTMLDivElement, idx: number) => {
    const view = matchDetailViews[idx]
    if (activeView !== view.key) {
      props.setMatchDetailView(view.key)
    }
  }

  const onScroll = (ev: React.UIEvent) => {
    const innerWidth = window.innerWidth / 2
    const elems = ev.currentTarget.children[0].children
    for (let i = 0; i < elems.length; i++) {
      const a = elems[i] as any
      const rect = a.getBoundingClientRect()
      if (rect.left < innerWidth && rect.right > innerWidth) {
        if (currentFilterScrollSelectTimeout) {
          clearTimeout(currentFilterScrollSelectTimeout)
        }
        const view = matchDetailViews[i]
        if (activeView !== view.key) {
          currentFilterScrollSelectTimeout = setTimeout(() => selectMatchFilterByElement(a, i), 100)
        }
      }
    }
  }

    return (
      <div className="Match-menu" ref={matchFilterRef} onScroll={onScroll}>
        <div className="Match-menu-views">
          {matchDetailViews.map((view, idx) =>
            <MatchDetailMenuItem
              key={view.key}
              idx={idx}
              displayName={view.displayName}
              view={view.key}
              focused={activeView === view.key}
              selected={activeView === view.key}
              applyView={applyView}
              applyScroll={applyScroll} />
            )}
        </div>
      </div>
    )

}

export default connect(mapStateToProps, mapDispatchToProps)(MatchDetailMenu)
