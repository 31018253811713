export type FavoritesState = string[]

const initialFavoritesState: FavoritesState = []

const TOGGLE_FAVORITE = "[Favorites] toggle favorite"

interface ToggleFavoriteAction {
  readonly type: typeof TOGGLE_FAVORITE
  payload: string
}

export const toggleFavorite = (matchUuid: string): ToggleFavoriteAction => ({ type: TOGGLE_FAVORITE, payload: matchUuid })

export const favoritesReducer = (state: FavoritesState = initialFavoritesState, action: ToggleFavoriteAction): FavoritesState => {

  switch (action.type) {
    case TOGGLE_FAVORITE:
      const matchUuid = action.payload
      const idx = state.indexOf(matchUuid)
      return idx > -1
        ? [ ...state.slice(0, idx), ...state.slice(idx + 1) ]
        : [ ...state, matchUuid ]
    default:
      return state
  }
}

export type FavouriteAction = ToggleFavoriteAction
