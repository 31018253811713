
import * as React from 'react';

import './EventEntry.scss'
import { DetailedTickerMatchState, TickerMatch, TickerTeam } from '../../../models';
import { ReactComponent as PointGuestIcon } from '../../../images/ticker-icons/point-guest.svg';
import { ReactComponent as PointHomeIcon } from '../../../images/ticker-icons/point-home.svg';
import { ReactComponent as ThreePointsGuestIcon } from '../../../images/ticker-icons/3-points-guest.svg';
import { ReactComponent as ThreePointsHomeIcon } from '../../../images/ticker-icons/3-points-home.svg';
import { ReactComponent as FivePointsGuestIcon } from '../../../images/ticker-icons/5-points-guest.svg';
import { ReactComponent as FivePointsHomeIcon } from '../../../images/ticker-icons/5-points-home.svg';
import { ReactComponent as SetBallIcon } from '../../../images/ticker-icons/set-ball.svg';
import { ReactComponent as MatchBallIcon } from '../../../images/ticker-icons/matchball.svg';
import { ReactComponent as TeamIcon } from '../../../images/ticker-icons/team.svg';
import { ReactComponent as CountdownIcon } from '../../../images/ticker-icons/countdown.svg';
import { ReactComponent as TimeoutIcon } from '../../../images/ticker-icons/time-out.svg';
import { ReactComponent as IconPlayerExchange } from '../../../images/ticker-icons/player-exchange.svg';
import { ReactComponent as KickOff } from '../../../images/ticker-icons/kick-off.svg';
import { ReactComponent as ListHome } from '../../../images/ticker-icons/list-home.svg';
import { ReactComponent as Punishment } from '../../../images/ticker-icons/punishment.svg';
import { ReactComponent as Disqualified } from '../../../images/ticker-icons/disqualified.svg';
import { ReactComponent as OutPosition } from '../../../images/ticker-icons/out-position.svg';
import { ReactComponent as Warning } from '../../../images/ticker-icons/warning.svg';
import { ReactComponent as TeamWarning } from '../../../images/ticker-icons/unauthorized-application.svg';
import { ReactComponent as TeamPenalty } from '../../../images/ticker-icons/unauthorized-application-2.svg';
import { ReactComponent as MatchBallTransformed } from '../../../images/ticker-icons/matchball-transformed.svg';
import { ReactComponent as MatchBallParried } from '../../../images/ticker-icons/matchball-parried.svg';
import { ReactComponent as SetBallTransformed } from '../../../images/ticker-icons/set-ball-transformed.svg';
import { ReactComponent as SetBallParried } from '../../../images/ticker-icons/set-ball-parried.svg';
import { ReactComponent as Mvp } from '../../../images/ticker-icons/player-game.svg';
import { ReactComponent as Challenge } from '../../../images/ticker-icons/challenge.svg';
import { ReactComponent as ChallengeSuccess } from '../../../images/ticker-icons/challenge-success.svg';
import { ReactComponent as ChallengeDeclined } from '../../../images/ticker-icons/challenge-declined.svg';
import { TeamRoster } from './events/TeamRoster';
import { StartingSix } from './events/StartingSix';
import { SetScoreDisplay } from './events/SetScoreDisplay';
import { getFullName } from '../../../utils';
import { TeamCode } from '../../../../../../shared/interfaces/models';
import { EventPlayer, EventTeamSquad, EventTeamLineup, EventOfficial } from '../../../../../../shared/event-api/model';
import { ChallengeDeclinedEvent,
  ChallengeEvent,
  ChallengeSuccessEvent,
  ConfirmTeamSquadEvent,
  IndividualDisqualificationEvent,
  IndividualExpulsionEvent,
  IndividualPenaltyEvent,
  IndividualWarningEvent,
  MatchBallDefendedEvent,
  MatchBallEvent,
  MatchBallSuccessEvent,
  SelectMvpEvent,
  SetBallDefendedEvent,
  SetBallEvent,
  SetBallSuccessEvent,
  StartMatchEvent,
  StartSetEvent,
  StartTimeoutEvent,
  SubstitutionEvent,
  TeamPenaltyEvent,
  TeamWarningEvent,
  TechnicalTimeoutEvent } from '../../../../../../shared/event-api/events';
import {
  InitializeMatchEvent,
  ScoreStreakEvent,
  TickerEvent
} from '../../../../../shared/models/events';

interface Props {
  match: TickerMatch
  matchState: DetailedTickerMatchState
  event: TickerEvent
}

interface EventData {
  title: string
  icon: JSX.Element
  element: JSX.Element
}

const NoneElement = <div></div>

export type LineupsByTeamCode = {
  [teamCode in TeamCode]: { players: EventPlayer[], defaultLibero: EventPlayer | undefined }
}

const getTeamName = (team: TickerTeam) => team.shortName ? team.shortName : team.name

const mapTeamLineup = (teamSquad: EventTeamSquad, lineup: EventTeamLineup) => {
  const players = lineup.playerUuids.map(uuid => teamSquad.players.find(p => p.uuid === uuid)) as EventPlayer[]
  const defaultLibero = teamSquad.players.find(p => p.uuid === lineup.defaultLiberoUuid)
  return { defaultLibero, players }
}

export const EventEntry = (props: Props): JSX.Element[] => {

  const getSanctionMember = (teamCode: TeamCode, teamMemberUuid: string) => {
    const { match, matchState } = props
    const team = match[teamCode]
    const player = matchState.teamSquads[teamCode].players.find(p => p.uuid === teamMemberUuid)
    const teamMember = player ? player : matchState.teamSquads[teamCode].officials.find(o => o.uuid === teamMemberUuid) as EventOfficial
    return `${getFullName(teamMember)} (${team.name})`
  }

  const getEventData = (): EventData[] | null => {

    const { event, match, matchState } = props
    const { teamSquads } = matchState
    const allPlayers = [...teamSquads.team1.players, ...teamSquads.team2.players]

    switch (event.type) {
      case 'SCORE':
      case 'PENALTY_SCORE': {
        const { setScore, teamCode, setNumber, streak } = event as ScoreStreakEvent
        if (streak !== 3 && streak !== 5) return []

        const teamThatScored = match[teamCode]
        const element = <div>Der aktuelle Punktestand im {setNumber}. Satz ist {setScore.team1} : {setScore.team2}.</div>

        const isHomeTeam = teamCode === 'team1'
        const title = `${streak} Punkte in Serie für ${getTeamName(teamThatScored)}`;

        let icon: JSX.Element
        if (streak === 3)
          icon = isHomeTeam ? <ThreePointsHomeIcon title={title} /> : <ThreePointsGuestIcon title={title} />
        else if (streak === 5)
          icon = isHomeTeam ? <FivePointsHomeIcon title={title} /> : <FivePointsGuestIcon title={title} />
        else
          icon = isHomeTeam ? <PointHomeIcon title={title} /> : <PointGuestIcon title={title} />


        return [{ title, icon, element }]
      }

      case 'START_MATCH': {
        const lineups = (event as StartMatchEvent).lineups
        const teamSquads = matchState.teamSquads

        const lineupsByTeamCode: LineupsByTeamCode = {
          team1: mapTeamLineup(teamSquads.team1, lineups!.team1),
          team2: mapTeamLineup(teamSquads.team2, lineups!.team2)
        }
        const element = <StartingSix lineupsByTeamCode={lineupsByTeamCode} match={match}></StartingSix>
        const kickOffTitle = 'Anpfiff'
        const listHomeTitle = 'Die Mannschaften beginnen den 1. Satz mit den folgenden Aufstellungen:'
        return [
          { title: kickOffTitle, icon: <KickOff title={kickOffTitle} />, element: NoneElement },
          { title: listHomeTitle, icon: <ListHome title={listHomeTitle} />, element },
        ]
      }

      case 'DELAY_POSSIBLE': {
        const title = 'Der Spielbeginn kann sich durch vorher stattfindende Veranstaltungen verzögern.'
        return [{ title, icon: <CountdownIcon title={title} />, element: NoneElement }]
      }

      case 'START_SET': {
        const { lineups, setNumber } = event as StartSetEvent
        const teamSquads = matchState.teamSquads

        const lineupsByTeamCode: LineupsByTeamCode = {
          team1: mapTeamLineup(teamSquads.team1, lineups.team1),
          team2: mapTeamLineup(teamSquads.team2, lineups.team2)
        }
        const element = <StartingSix lineupsByTeamCode={lineupsByTeamCode} match={match}></StartingSix>
        const title = `Die Mannschaften beginnen den ${setNumber}. Satz mit den folgenden Aufstellungen:`
        return [
          { title, icon: <ListHome title={title} />, element },
        ]
      }

      case 'SET_BALL': {
        const { numberOfSetBalls, teamCode } = event as SetBallEvent
        const setBalls = numberOfSetBalls > 1 ? numberOfSetBalls + ' Satzbälle' : 'Satzball'
        const title = setBalls + ' für ' + getTeamName(match[teamCode]);
        return [{ title, icon: <SetBallIcon title={title} />, element: NoneElement }]
      }

      case 'SET_BALL_SUCCESS': {
        const { teamCode } = event as SetBallSuccessEvent
        const title = 'Satzball verwandelt ' + getTeamName(match[teamCode]);
        return [{ title, icon: <SetBallTransformed title={title} />, element: NoneElement }]
      }

      case 'SET_BALL_DEFENDED': {
        const { teamCode, numberOfSetBalls } = event as SetBallDefendedEvent
        const title = `Satzball abgewehrt ${getTeamName(match[teamCode])}. Verbleibend: ${numberOfSetBalls}`;
        return [{ title, icon: <SetBallParried title={title} />, element: NoneElement }]
      }

      case 'MATCH_BALL': {
        const { numberOfMatchBalls, teamCode } = event as MatchBallEvent
        const setBalls = numberOfMatchBalls > 1 ? numberOfMatchBalls + ' Matchbälle' : 'Matchball'
        const title = setBalls + ' für ' + getTeamName(match[teamCode]);
        return [{ title, icon: <MatchBallIcon title={title} />, element: NoneElement }]
      }

      case 'MATCH_BALL_SUCCESS': {
        const { teamCode } = event as MatchBallSuccessEvent
        const title = 'Matchball verwandelt ' + getTeamName(match[teamCode]);
        return [{ title, icon: <MatchBallTransformed title={title} />, element: NoneElement }]
      }

      case 'MATCH_BALL_DEFENDED': {
        const { teamCode, numberOfMatchBalls } = event as MatchBallDefendedEvent
        const title = `Matchball abgewehrt ${getTeamName(match[teamCode])}. Verbleibend: ${numberOfMatchBalls}`;
        return [{ title, icon: <MatchBallParried title={title} />, element: NoneElement }]
      }

      case 'SUBSTITUTION': {
        const { playerInUuid, playerOutUuid, teamCode } = event as SubstitutionEvent
        const playerIn = allPlayers.find(p => p.uuid === playerInUuid)!
        const playerOut = allPlayers.find(p => p.uuid === playerOutUuid)!

        const title = 'Wechsel bei ' + getTeamName(match[teamCode]);
        const element = <div>{playerIn.lastName} kommt für {playerOut.lastName}</div>
        return [{ title, element, icon: <IconPlayerExchange title={title} /> }]
      }

      case 'CONFIRM_TEAMSQUAD': {
        const { teamCode, teamSquad } = event as ConfirmTeamSquadEvent
        const team = match[teamCode]
        const title = 'Mannschaftsliste ' + team.name
        const element = <TeamRoster teamSquad={teamSquad}></TeamRoster>
        return [{ title, icon: <TeamIcon title={title} />, element }]
      }

      case 'TECHNICAL_TIMEOUT': {
        const { setScore, timeoutNumber } = event as TechnicalTimeoutEvent
        const title = timeoutNumber + '. Technische Auszeit'
        return [{ title, icon: <TimeoutIcon title={title} />, element: <SetScoreDisplay setScore={setScore}></SetScoreDisplay> }]
      }

      case 'START_TIMEOUT': {
        const { setScore, teamCode } = event as StartTimeoutEvent
        const title = `Auszeit ${getTeamName(match[teamCode])}`
        return [{ title, icon: <TimeoutIcon title={title} />, element: <SetScoreDisplay setScore={setScore}></SetScoreDisplay> }]
      }

      case 'INDIVIDUAL_WARNING': {
        const { teamCode, teamMemberUuid } = event as IndividualWarningEvent
        const formattedMember = getSanctionMember(teamCode, teamMemberUuid)
        const element = <div>Gelbe Karte für {formattedMember}</div>
        const title = 'Gelbe Karte'
        return [{ title, icon: <Warning title={title} />, element }]
      }

      case 'INDIVIDUAL_PENALTY': {
        const { teamCode, teamMemberUuid } = event as IndividualPenaltyEvent
        const otherTeamCode: TeamCode = teamCode === 'team1' ? 'team2' : 'team1'
        const otherTeam = match[otherTeamCode]
        const formattedMember = getSanctionMember(teamCode, teamMemberUuid)
        const element = <div>
          <p>Rote Karte für {formattedMember}</p>
          <p>Punkt für {otherTeam.name}</p>
        </div>
        const title = 'Rote Karte'
        return [{ title, icon: <Punishment title={title} />, element }]
      }

      case 'INDIVIDUAL_EXPULSION': {
        const { teamCode, teamMemberUuid } = event as IndividualExpulsionEvent
        const formattedMember = getSanctionMember(teamCode, teamMemberUuid)
        const element = <div>Hinausstellung für {formattedMember}: Er/Sie darf für den Rest des Satzes nicht mehr mitspielen, aber kann im kommenden Satz wieder eingesetzt werden.</div>
        const title = 'Hinausstellung'
        return [{ title, icon: <OutPosition title={title} />, element }]
      }

      case 'INDIVIDUAL_DISQUALIFICATION': {
        const { teamCode, teamMemberUuid } = event as IndividualDisqualificationEvent
        const formattedMember = getSanctionMember(teamCode, teamMemberUuid)
        const element = <div>Disqualifikation für {formattedMember}: Ein weiteres Mitwirken ist heute nicht mehr möglich.</div>
        const title = 'Disqualifikation'
        return [{ title, icon: <Disqualified title={title} />, element }]
      }

      case 'TEAM_WARNING': {
        const { teamCode } = event as TeamWarningEvent
        const team = match[teamCode]
        const title = 'Verwarnung für ' + team.name
        return [{ title, icon: <TeamWarning title={title} />, element: <div>Verwarnung wegen Verzögerung gegen {team.name}</div> }]
      }


      case 'TEAM_PENALTY': {
        const { teamCode } = event as TeamPenaltyEvent
        const otherTeamCode: TeamCode = teamCode === 'team1' ? 'team2' : 'team1'
        const team = match[teamCode]
        const otherTeam = match[otherTeamCode]
        const element = <div><p>Bestrafung wegen Verzögerung gegen {team.name}</p><p>Punkt für {otherTeam.name}</p></div>
        const title = 'Bestrafung für ' + team.name
        return [{ title, icon: <TeamPenalty title={title} />, element }]
      }

      case 'SELECT_MVP': {
        const { teamCode, playerUuid } = event as SelectMvpEvent
        const team = match[teamCode]
        const player = matchState.teamSquads[teamCode].players.find((p: EventPlayer) => p.uuid === playerUuid) as EventPlayer
        const title = `MVP ${team.name}: ${getFullName(player)}`
        return [{ title, icon: <Mvp title={title} />, element: NoneElement }]
      }

      case 'CHALLENGE': {
        const { teamCode } = event as ChallengeEvent
        const team = match[teamCode]
        const title = `Video-Challenge beantragt von ${getTeamName(team)}`
        return [{ title, icon: <Challenge title={title} />, element: NoneElement }]
      }

      case 'CHALLENGE_SUCCESS': {
        const { teamCode, setScore, challengesLeft } = event as ChallengeSuccessEvent
        const team = match[teamCode]
        const title = `Challenge erfolgreich! Aktueller Punktestand: ${setScore.team1} : ${setScore.team2}`
        const element = <div>{getTeamName(team)} hat weiterhin {challengesLeft} Challenge-Anträge</div>
        return [{ title, icon: <ChallengeSuccess title={title} />, element }]
      }

      case 'CHALLENGE_DECLINED': {
        const { teamCode, setScore, challengesLeft } = event as ChallengeDeclinedEvent
        const team = match[teamCode]
        const title = `Challenge nicht erfolgreich! Aktueller Punktestand: ${setScore.team1} : ${setScore.team2}`
        const element = challengesLeft === 0
          ? <div>{getTeamName(team)} hat für diesen Satz keine Challenge-Anträge mehr</div>
          : <div>{getTeamName(team)} hat noch {challengesLeft} Challenge-Anträge in diesem Satz</div>
        return [{ title, icon: <ChallengeDeclined title={title} />, element }]
      }

      default:
        return null
    }
  }

  const e = props.event
  const eventData = getEventData();
  if (!eventData) return [<div key={0}></div>]
  // InitializeMatchEvent does not have eventData, so we can safely exclude it
  const event = e as Exclude<TickerEvent, InitializeMatchEvent>
  const ts = new Date(event.timestamp);
  const minutes = ts.getMinutes() < 10 ? '0' + ts.getMinutes() : ts.getMinutes()
  const hours = ts.getHours()

  return eventData.map((e, i) => {
    return <div key={i} className="EventEntry tile tile-shadow">
      <div className="icon">{e.icon}</div>
      <div className="content">
        <h2>{e.title}</h2>
        {e.element}
      </div>
      <div className="meta">
        {event.type === 'START_MATCH' || event.type === 'CONFIRM_TEAMSQUAD'
          ? <div className="time"><div>{hours}</div><div>:</div><div>{minutes}</div></div>
          : <div className="time"><div>{event.setScore.team1}</div><div>:</div><div>{event.setScore.team2}</div></div>
        }
        <div className="score"><div>{event.setPoints.team1}</div><div>{event.setPoints.team2}</div></div>
      </div>
    </div>
  })

}
