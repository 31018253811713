import * as React from 'react';

import './MatchDetail.scss'
import MatchDetailHeader from './MatchDetailHeader';
import { Status, DetailedTickerMatchState, TickerMatch, TickerMatchSeries } from '../../models';
import { Helmet } from 'react-helmet';
import { TickerMatchStats } from '../../models/index';
import MatchDetailBody from './MatchDetailBody';
import { MatchDetailView } from './detail-view/reducer';
import { useWindowSize } from '@react-hook/window-size';
import { Rankings } from './rankings/Rankings';
import { LiveReferees } from './live-referees/LiveReferees';
import MatchStats from '../match-stats/MatchStats';
import { LiveTicker } from './live-ticker/LiveTicker';
import { LiveLineups } from './live-lineups/LiveLineups';

interface Props {
  match: TickerMatch
  matchState: DetailedTickerMatchState
  matchSeries: TickerMatchSeries
  matchStats: TickerMatchStats
  status: Status
  matchDetailView: MatchDetailView
}

export interface MatchDetailViewInstance {
  key: MatchDetailView
  displayName: string
  element: JSX.Element
}

const MatchDetail = (props: Props) => {

    const {
      match,
      matchState,
      matchSeries,
      status,
      matchStats,
      matchDetailView
    } = props

    const liveStatisticsEnabled = matchState.liveStatisticsEnabled
    const [width] = useWindowSize()

    const BREAK_POINT = 568
    const TABLET_BREAK_POINT = 1000

    const desktopView: MatchDetailViewInstance = {
      key: MatchDetailView.LIVE_TICKER,
      displayName: "Live Ticker",
      element: <div key={MatchDetailView.LIVE_TICKER} className="MatchDetailBody desktop">
        <LiveLineups matchState={matchState} match={match}/>
        <LiveTicker matchState={matchState} match={match}/>
        <div className="right-column">
          {matchSeries.rankings?.fullRankings?.length !== 0 && <Rankings matchSeries={matchSeries}/>}
          {matchState.arbitration && <LiveReferees arbitration={matchState.arbitration} match={match}/>}
        </div>
      </div>
    }

    const tabletView: MatchDetailViewInstance = {
      key: MatchDetailView.LIVE_TICKER,
      displayName: "Live Ticker",
      element: <div key={MatchDetailView.LIVE_TICKER} className="MatchDetailBody desktop">
        <div className="left-column">
          <LiveLineups matchState={matchState} match={match}/>
          {matchSeries.rankings?.fullRankings?.length !== 0 && <Rankings matchSeries={matchSeries}/>}
          {matchState.arbitration && <LiveReferees arbitration={matchState.arbitration} match={match}/>}
        </div>
        <LiveTicker matchState={matchState} match={match}/>
      </div>
    }

    const fullView = width > TABLET_BREAK_POINT ? desktopView : tabletView

    const liveTickerView: MatchDetailViewInstance = {
      key: MatchDetailView.LIVE_TICKER,
      displayName: "Live Ticker",
      element: <LiveTicker key={MatchDetailView.LIVE_TICKER} matchState={matchState} match={match}/>,
    }

    const matchStatsView: MatchDetailViewInstance = {
      key: MatchDetailView.MATCH_STATS,
      displayName: "Statistik",
      element: <MatchStats key={MatchDetailView.MATCH_STATS} matchStats={matchStats} match={match} matchState={matchState} matchSeries={matchSeries}></MatchStats>
    }

    const lineupsView: MatchDetailViewInstance = {
      key: MatchDetailView.LINEUP,
      displayName: "Aufstellung",
      element: <LiveLineups key={MatchDetailView.LINEUP} matchState={matchState} match={match}/>
    }

    const refereesView: MatchDetailViewInstance = {
      key: MatchDetailView.REFEREES,
      displayName: "Schiedsrichter",
      element: <LiveReferees key={MatchDetailView.REFEREES} arbitration={matchState.arbitration} match={match}/>
    }

    const rankingsView: MatchDetailViewInstance = {
      key: MatchDetailView.RANKINGS,
      displayName: "Tabelle",
      element: <Rankings key={MatchDetailView.RANKINGS} matchSeries={matchSeries}/>
    }

    const desktopViews: MatchDetailViewInstance[] = [
      fullView,
      matchStatsView 
    ]

    const mobileViews: MatchDetailViewInstance[] = [
      liveTickerView,
      lineupsView,
      matchStatsView,
      refereesView,
      rankingsView
    ]

    const isDesktopView = width > BREAK_POINT

    let matchDetailViews = isDesktopView
      ? desktopViews
      : mobileViews

    if (!liveStatisticsEnabled) {
      matchDetailViews = matchDetailViews.filter(m => m.key !== MatchDetailView.MATCH_STATS)
    }

    if (!matchState.arbitration) {
      matchDetailViews = matchDetailViews.filter(m => m.key !== MatchDetailView.REFEREES)
    }

    if (matchSeries.rankings?.fullRankings?.length === 0) {
      matchDetailViews = matchDetailViews.filter(m => m.key !== MatchDetailView.RANKINGS)
    }

    return <div className="MatchDetail">
      <Helmet>
        <title>{match.teamDescription1} - {match.teamDescription2}</title>
      </Helmet>
      <MatchDetailHeader isDesktopView={isDesktopView} matchDetailViews={matchDetailViews} match={match} matchState={matchState} matchSeries={matchSeries} />
      <MatchDetailBody isDesktopView={isDesktopView} matchDetailViews={matchDetailViews} matchDetailView={matchDetailView} status={status} match={match} matchState={matchState} matchSeries={matchSeries} matchStats={matchStats} />
    </div>

}

export default MatchDetail
