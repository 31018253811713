import { Status, TickerMatchSeriesByUuid } from './models';
import { TickerMatchState, TickerMatch, TickerMatchSeries } from './models';
import { EventPlayer, EventOfficial, EventArbitrationMember } from '../../../shared/event-api/model';
import { TickerMatchStatesByUuid } from './ticker-state/reducer';
import { useState, useEffect } from 'react';

export const flatten = <T>(x: T[][]) => [].concat(...x as any) as T[]
export const flatMap = <T, R>(mapper: (x: T) => R[], x: T[]) => flatten(x.map(mapper)) as R[]

export const getToday = () => new Date()
export const getYesterday = (today: Date) => {
  let result = new Date(today)
  result.setDate(today.getDate()-1)
  return result
}
export const getTomorrow = (today: Date) => {
  let result = new Date(today)
  result.setDate(today.getDate()+1)
  return result
}
const getDay = (date: Date) => date.toLocaleDateString()

export const isNamedDay = (date: Date) => {
  const today = getToday()
  if (getDay(date) === getDay(getToday())
    || getDay(date) === getDay(getTomorrow(today))
    || getDay(date) === getDay(getYesterday(today))) {
    return true
  }
  return false
}

export const getInitials = (person: EventPlayer | EventOfficial | EventArbitrationMember) => {
  let initials = '';
  if (person.firstName != null && person.firstName.length > 0) {
    initials = person.firstName.substring(0, 1);
  }
  if (person.lastName != null && person.lastName.length > 0) {
    initials += person.lastName.substring(0, 1);
  }
  return initials
}

export const getFullName = (person: EventPlayer | EventOfficial | EventArbitrationMember) =>
  person.firstName + ' ' + person.lastName

export const formatDate = (date: Date, lang = 'de'): string => {
  const dateOpts = { day: '2-digit' as '2-digit', weekday: 'short' as 'short', month: '2-digit' as '2-digit' }
  return date.toLocaleString(lang, dateOpts).replace(',', '')
}

export const formatTime = (date: Date, lang = 'de'): string => {
  const timeOpts = { hour: '2-digit' as '2-digit', minute:'2-digit' as '2-digit' }
  return date.toLocaleString(lang, timeOpts)
}

export const getDayName = (date: Date) => {
  const today = getToday()
  const day = date.toLocaleDateString()
  if (day === getYesterday(today).toLocaleDateString()) {
    return "Gestern"
  } else if(day === getTomorrow(today).toLocaleDateString()) {
    return "Morgen"
  } else if(day === today.toLocaleDateString()) {
    return "Heute"
  } else {
    return formatDate(date)
  }
}

export const stripSpaces = (s: string) => s.replace(/\s/g, "") 

export const leadingZero = (num: number) => num > 0 ? String(num).padStart(2, '0') : 0

const sortByMatchDate = (a: TickerMatch, b: TickerMatch) => {
  return a.date - b.date
}

const matchStateSortRank = (matchState: TickerMatchState) => {
  const status = getMatchStatus(matchState)
  if (status === Status.PRE) return 1; // PRE
  if (status === Status.POST) return 2; // POST
  return 0; // LIVE
}

const sortByMatchState = (a: TickerMatchState, b: TickerMatchState) => matchStateSortRank(a) - matchStateSortRank(b)

export const sortMatches =
  (matchStatesByUuid: TickerMatchStatesByUuid, matchSeries: TickerMatchSeriesByUuid) =>
  (matchA: TickerMatch, matchB: TickerMatch) => {

  const matchStateA = matchStatesByUuid[matchA.id]
  const matchStateB = matchStatesByUuid[matchB.id]
  const sortedByMatchState = sortByMatchState(matchStateA, matchStateB)
  if (sortedByMatchState === 0) {
    const sortedByDate = sortByMatchDate(matchA, matchB)
    if (sortedByDate === 0) {
      const matchSeriesA = matchSeries[matchA.matchSeries]
      const matchSeriesB = matchSeries[matchB.matchSeries]
      const sortedByMatchSeries = matchSeriesA.orderLevel - matchSeriesB.orderLevel
      return sortedByMatchSeries
    }
    return sortedByDate
  }
  return sortedByMatchState
}

export const getMatchDetailPath = (match: TickerMatch) => 
  '/detail/' + match.id

export const getMatchStatsPath = (match: TickerMatch) => 
  '/stats/' + match.id

export const getMatchSeriesPath = (matchSeries: TickerMatchSeries) =>
  '/matchSeries/' + stripSpaces(matchSeries.id)

export const getMatchStatus = (matchState?: TickerMatchState) =>
  (matchState && matchState.ready) ? (matchState.finished ? Status.POST : Status.LIVE) : Status.PRE

/**
 * useScroll React custom hook
 * Usage:
 *    const scrollY = useScroll();
 */


export function useScroll() {
  const [bodyOffset, setBodyOffset] = useState(
    document.body.getBoundingClientRect()
  );
  const [scrollY, setScrollY] = useState(bodyOffset.top);

  const listener = (e: any) => {
    setBodyOffset(document.body.getBoundingClientRect());
    setScrollY(-bodyOffset.top);
  };

  useEffect(() => {
    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  });

  return scrollY
}

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined as any as number,
    height: undefined as any as number,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    
    // Add event listener
    window.addEventListener("resize", handleResize);
    
    // Call handler right away so state gets updated with initial window size
    handleResize();
    
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export interface ScrollData {
  left: number
  clientWidth: number
}
