import * as React from "react";
import './MatchInfo.scss'
import { CurrentSetScore } from '../../match-cards/match-card/CurrentSetScore';
import { Time } from '../../../shared/Time';
import { TickerMatchState, TickerMatch } from '../../../models';
import { TeamScore } from "../../../match/TeamScore";

interface Props {
  matchState?: TickerMatchState
  match: TickerMatch
}

export const MatchInfo = (props: Props) => {

  const { matchState, match } = props
  const { formattedTime, indefinitelyRescheduled } = match
  if (matchState && matchState.started) {
    return (
      <div className="Match-score">
        <TeamScore score={matchState.setPoints.team1} finished={matchState.finished} />
        <CurrentSetScore matchState={matchState} />
        <TeamScore score={matchState.setPoints.team2} finished={matchState.finished} />
      </div>
    )
  } else {
    return (
      <div className={"Match-date" + (indefinitelyRescheduled ? ' rescheduled' : '')}>
        <Time formattedTime={formattedTime} />
      </div>
    )
  }

}
