import * as React from "react";
import { PlayerListing } from "./PlayerListing";
import { EventTeamSquad } from "../../../../../../../shared/event-api/model";

interface Props {
  teamSquad: EventTeamSquad
}

export const TeamRoster = (props: Props) => {
  const { teamSquad } = props
  const trainer = teamSquad.officials.find(o => o.function === 'TRAINER')
  return <div>
    <ul>
      {teamSquad.players.map(p => <PlayerListing key={p.uuid} player={p}></PlayerListing>)}
    </ul>
    { trainer && <div>Trainer/in: {trainer.firstName} {trainer.lastName}</div> }
    </div>
}
