
import * as React from "react";
import './StartingSix.scss'
import { PlayerListing } from "./PlayerListing";
import { LineupsByTeamCode } from "../EventEntry";
import { TickerMatch } from "../../../../models";

interface Props {
  lineupsByTeamCode: LineupsByTeamCode
  match: TickerMatch
}

export const StartingSix = (props: Props) => {
  const { lineupsByTeamCode, match } = props
  return <div className="StartingSix">
    <h2 className="team-name">{match.team1.name}</h2>
    <ul>
      {lineupsByTeamCode.team1.players.map(p => <PlayerListing key={p.uuid} player={p}></PlayerListing>)}
      {lineupsByTeamCode.team1.defaultLibero && <PlayerListing key={lineupsByTeamCode.team1.defaultLibero.uuid} isLibero={true} player={lineupsByTeamCode.team1.defaultLibero}></PlayerListing>}
    </ul>
    <h2 className="team-name">{match.team2.name}</h2>
    <ul>
      {lineupsByTeamCode.team2.players.map(p => <PlayerListing key={p.uuid} player={p}></PlayerListing>)}
      {lineupsByTeamCode.team2.defaultLibero && <PlayerListing key={lineupsByTeamCode.team2.defaultLibero.uuid} isLibero={true} player={lineupsByTeamCode.team2.defaultLibero}></PlayerListing>}
    </ul>
  </div>
}
