import { Gender } from "../../../shared/models/api"

const SET_GENDER_FILTER = '[FilterNav] set gender filter'

export const setGenderFilter = (gender?: Gender): SetGenderFilterAction => ({ type: SET_GENDER_FILTER, payload: gender })

interface SetGenderFilterAction {
  readonly type: typeof SET_GENDER_FILTER
  payload?: Gender
}

interface FilterNavState {
  gender?: Gender
}

const initialMultiFilterState: FilterNavState = {
  gender: undefined
}

export const filterNavReducer = (state: FilterNavState = initialMultiFilterState, action: FilterNavAction): FilterNavState => {
  switch(action.type) {
    case SET_GENDER_FILTER:
      return {
        ...state,
        gender: action.payload,
      }
   default:
      return state
  }
}

export type FilterNavAction = SetGenderFilterAction
