import { OpenEvent } from "reconnectingwebsocket"

const CONNECTION_CLOSED = '[Connection] closed'
const CONNECTION_TERMINATED = '[Connection] terminated'
const CONNECTION_OPENED = '[Connection] opened'

interface ConnectionTerminatedAction {
  readonly type: typeof CONNECTION_TERMINATED
}

interface ConnectionClosedAction {
  readonly type: typeof CONNECTION_CLOSED
  reason: string
}

interface ConnectionOpenedAction {
  readonly type: typeof CONNECTION_OPENED
}

export const connectionOpened = (_: OpenEvent): ConnectionOpenedAction => ({ type: CONNECTION_OPENED })
export const connectionTerminated = (): ConnectionTerminatedAction => ({ type: CONNECTION_TERMINATED })
export const connectionClosed = (event: CloseEvent): ConnectionClosedAction => ({ type: CONNECTION_CLOSED, reason: event.reason })

export interface ConnectionState {
  status: ConnectionStatus
  message?: string
}

export enum ConnectionStatus {
  CONNECTING = 'CONNECTING',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  TERMINATED = 'TERMIANTED'
}

const initialState = {
  connected: false,
  status: ConnectionStatus.CONNECTING
}

export const connectionReducer = (state: ConnectionState = initialState, action: ConnectionAction): ConnectionState => {

  if (action.type === CONNECTION_OPENED) {
    return {
      ...state,
      status: ConnectionStatus.OPEN,
      message: undefined
    }
  }
  if (action.type === CONNECTION_CLOSED) {
    return {
      ...state,
      status: ConnectionStatus.CLOSED,
      message: 'Der Live Ticker konnte nicht geladen werden.'
    }
  }
  if (action.type === CONNECTION_TERMINATED) {
    return {
      ...state,
      status: ConnectionStatus.TERMINATED,
      message: 'Verbindung unterbrochen. Bitte prüfen Sie Ihre Internetverbindung.'
    }
  }
 return state

}

export type ConnectionAction = ConnectionClosedAction | ConnectionOpenedAction | ConnectionTerminatedAction
