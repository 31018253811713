import * as React from "react";
import { connect } from "react-redux";
import { ScrollData } from "../../../utils";
import "./MatchDetailMenuItem.scss";
import { MatchDetailView } from './reducer';

interface Props {
  focused: boolean
  selected: boolean
  idx: number
  displayName: string
  view: MatchDetailView
  applyView: (view: MatchDetailView, scrollData: ScrollData) => void,
  applyScroll: (view: MatchDetailView, scrollData: ScrollData) => void
}

class MatchDetailMenuItem extends React.PureComponent<Props> {

  private ref = React.createRef<HTMLDivElement>()

  public componentDidMount() {
    if (this.props.selected) {
      this.applyView()
    }
  }

  componentDidUpdate() {
    if (this.props.selected) {
      const view = this.ref.current!
      const left = view.getBoundingClientRect().left
      const clientWidth = view.clientWidth
      this.props.applyScroll(this.props.view, { left, clientWidth })
    }
  }

  applyView = () => {
    const view = this.ref.current!
    const left = view.getBoundingClientRect().left
    const clientWidth = view.clientWidth
    this.props.applyView(this.props.view, { left, clientWidth })
  }

  render() {
    return (
      <div
        className={'menu-item ' + (this.props.focused ? 'active' : '')}
        onClick={this.applyView}
        ref={this.ref}>
        {this.props.displayName}
      </div>
    )
  }

}

export default connect()(MatchDetailMenuItem)
