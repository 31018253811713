export enum ViewSelectionState {
  List = "LIST_VIEW",
  Cards ="CARDS_VIEW"
}

const initialState: ViewSelectionState = ViewSelectionState.Cards

const CHANGE_VIEW = "[ViewSelection] change view"

interface ChangeViewAction {
  readonly type: typeof CHANGE_VIEW 
  payload: ViewSelectionState
}

export const changeView = (view: ViewSelectionState): ChangeViewAction => ({ type: CHANGE_VIEW, payload: view })

export const viewSelectionReducer = (state: ViewSelectionState = initialState, action: ChangeViewAction): ViewSelectionState => {
  switch (action.type) {
    case CHANGE_VIEW:
      return action.payload
    default:
      return state
  }
}
