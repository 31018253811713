import * as React from "react";

import './MatchCard.scss';
import { Status } from '../../../models';
import { MatchCardHeader } from "./MatchCardHeader";
import { MatchCardBody } from './MatchCardBody';
import { NavLink } from 'react-router-dom';
import { getMatchDetailPath } from '../../../utils';
import { TickerMatchState, TickerMatch, TickerMatchSeries } from '../../../models';
import { TickerMode } from "../../../../../shared/models/ticker";
import { MatchCardFooter } from "./MatchCardFooter";
import { FavoritesState } from '../../../match/favourite/reducer';

interface Props {
  match: TickerMatch
  matchState: TickerMatchState
  matchSeries: TickerMatchSeries | undefined
  matchUuid: string
  status: Status
  scoreSheetPdfUrl?: string
  mode: TickerMode
  favorites?: FavoritesState
}

export const MatchCard = (props: Props) => {

  const {
    match,
    matchState,
    matchSeries,
    matchUuid,
    status,
    scoreSheetPdfUrl,
    mode,
    favorites
  } = props

  return (
    <div className={"MatchCard tile tile-shadow" + (matchState?.finished ? " finished" : "")}>
      {status !== Status.PRE && mode === 'DETAILED' ?
        <NavLink
          key={match.id}
          to={getMatchDetailPath(match)}>
          <div>
            <MatchCardHeader match={match} matchSeries={matchSeries} matchState={matchState} />
            <MatchCardBody match={match} matchState={matchState} />
          </div>
        </NavLink>
        : <div className="wrapper">
          <MatchCardHeader match={match} matchSeries={matchSeries} matchState={matchState} />
          <MatchCardBody match={match} matchState={matchState} />
        </div>
      }
      <MatchCardFooter favorites={favorites} matchUuid={matchUuid} matchState={matchState} mode={mode} status={status} scoreSheetPdfUrl={scoreSheetPdfUrl} />
    </div>
  )
}
