import { useEffect } from 'react';
import { useLocation } from 'react-router';

const ScrollToTop: any = ({ children }: any) => {
  let pathname = useLocation().pathname
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children
};

export default ScrollToTop;
