const SET_ACTIVE_FILTER = '[DayFilter] set active filter'
const SET_FOCUSED_FILTER = '[DayFilter] set focused filter'

export const setActiveFilter = (filter: number): SetActiveFilterAction => ({ type: SET_ACTIVE_FILTER, payload: filter })
export const setFocusedFilter = (filter: number): SetFocusedFilterAction => ({ type: SET_FOCUSED_FILTER, payload: filter })

interface SetActiveFilterAction {
  readonly type: typeof SET_ACTIVE_FILTER
  payload: number
}

interface SetFocusedFilterAction {
  readonly type: typeof SET_FOCUSED_FILTER
  payload: number
}

interface DayFilterState {
  activeFilterDay: number | null
  focusedFilterDay: number | null
}

const initialMultiFilterState: DayFilterState = {
  activeFilterDay: null,
  focusedFilterDay: null
}

export const dayFilterReducer = (state: DayFilterState = initialMultiFilterState, action: DayFilterAction): DayFilterState => {
  switch(action.type) {
    case SET_ACTIVE_FILTER:
      return {
        ...state,
        activeFilterDay: action.payload,
        focusedFilterDay: action.payload
      }
    case SET_FOCUSED_FILTER: 
      return {
        ...state,
        focusedFilterDay: action.payload
      }
   default:
      return state
  }
}

export type DayFilterAction = SetActiveFilterAction | SetFocusedFilterAction
