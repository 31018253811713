import * as React from "react";
import './TeamScore.scss';

interface Props {
  score: number
  finished: boolean
}

export const TeamScore = React.memo((props: Props) => {
  const score = props.score
  return (
    <div className={"TeamScore" + (props.finished ? " finished" : "")} style={{ textAlign: 'center' }}>{score}</div>
  )
})
