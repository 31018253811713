import * as React from "react";
import { Time } from "../shared/Time";
import { TickerMatch } from "../models";

interface Props {
  match: TickerMatch
}

export const MatchDate = React.memo((props: Props) => {
  const { match } = props
  return (
    <div className="Match-date">
      <div>{match.formattedDate}</div>
      <Time formattedTime={match.formattedTime} />
    </div>
  )
})
