import * as React from 'react';

import { Avatar } from '../../../shared/Avatar';
import { getFullName, getInitials } from '../../../utils';
import { EventArbitrationMember } from '../../../../../../shared/event-api/model';

interface Props {
  arbitrationMember: EventArbitrationMember
  type: string
}

export const RefereeCard = (props: Props) => {

  const { arbitrationMember, type } = props
  const name = getFullName(arbitrationMember)
  const initials = getInitials(arbitrationMember)
  return <div className="tile tile-shadow">
      <Avatar placeholder={initials}/>
      <div className="LiveReferees-name">{name}</div>
      <div className="LiveReferees-type">{type}</div>
    </div>

}
