import * as React from 'react';

import { connect } from 'react-redux';
import { AssociationRootState } from '..';
import './MainNav.scss';

const mapStateToProps = (state: AssociationRootState) => {
  return {
    navLinkItems: state.tickerState.settings.navLinkItems
  }
}

type Props = ReturnType<typeof mapStateToProps>

const MainNav = React.memo((props: Props) => {
  const { navLinkItems } = props

  return <div className="MainNav">
    {navLinkItems.map((item, i) =>
      <a key={i} href={item.url} rel="noopener" target={item.openInNewTab ? '_blank' : '_self'}>{item.name}</a>)}
    <a key={navLinkItems.length} href="/datenschutz.html" target="_blank">Datenschutz</a>
    <a key={navLinkItems.length + 1} href='/impressum.html' target="_blank">Impressum</a>
  </div>
})

export default connect(mapStateToProps)(MainNav)
