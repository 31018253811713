import {
  TickerSettings,
  TickerTimeInterval,
  TickerApiMatchState
} from "../../../../ticker/shared/models/ticker";
import {
  EventMatchState,
  TickerMatchState,
  TickerMatchDays,
  TickerMatchSeriesByUuid,
  TickerMatchStats
} from '../models';
import {
  ConfirmTeamSquadEvent,
  ConfirmArbitrationEvent
} from '../../../../shared/event-api/events';
import {
  EventTeamSquad,
  EventArbitration,
  EventTeamSquads
} from '../../../../shared/event-api/model';
import { MatchConfiguration } from '../../../../shared/interfaces/models';
import { mapTickerMatchStates } from '../socket';
import { Gender } from "../../../shared/models/api";

export interface TickerMatchStatesByUuid {
  [matchUuid: string]: TickerMatchState
}

export interface TickerMatchStatsByUuid {
  [matchUuid: string]: TickerMatchStats
}

export interface AssociationTickerState {
  id?: string
  matchSeries: TickerMatchSeriesByUuid
  matchDays: TickerMatchDays
  matchStatesByUuid: TickerMatchStatesByUuid,
  matchStatsByUuid: TickerMatchStatsByUuid,
  settings: TickerSettings,
  loading: boolean
  filterNav?: Gender
  error?: string
}

const initialAssociationTickerState: AssociationTickerState = {
  matchSeries: {} as TickerMatchSeriesByUuid,
  matchDays: [] as TickerMatchDays,
  matchStatesByUuid: {},
  matchStatsByUuid: {},
  filterNav: undefined,
  settings: {
    navLinkItems: [],
    footer: {},
    timeInterval: {} as TickerTimeInterval,
    mode: "SIMPLE",
    days: []
  } as TickerSettings,
  loading: true
}

const LOAD_ASSOCIATION_TICKER_STATE_SUCCESS = "[TickerState] load association ticker state success"
const LOAD_TICKER_STATE_FAILURE = "[TickerState] load ticker state failure"
const MATCH_UPDATE = "[TickerState] match update"
const MATCH_STATS_UPDATE = "[TickerState] match stats update"

export interface LoadAssociationTickerStatePayload {
  matchSeries: TickerMatchSeriesByUuid 
  matchDays: TickerMatchDays
  matchStates: TickerMatchStatesByUuid
  matchStats: TickerMatchStatsByUuid
  settings: TickerSettings
}

interface LoadAssociationTickerStateSuccessAction {
  readonly type: typeof LOAD_ASSOCIATION_TICKER_STATE_SUCCESS
  payload: LoadAssociationTickerStatePayload
}

interface LoadTickerStateFailureAction {
  readonly type: typeof LOAD_TICKER_STATE_FAILURE
  payload: string
}

interface MatchUpdateAction {
  readonly type: typeof MATCH_UPDATE
  payload: TickerMatchState
}

interface MatchStatsUpdateAction {
  readonly type: typeof MATCH_STATS_UPDATE
  payload: TickerMatchStats
}

export const loadAssociationTickerStateSuccess = (payload: LoadAssociationTickerStatePayload): LoadAssociationTickerStateSuccessAction => ({
  type: LOAD_ASSOCIATION_TICKER_STATE_SUCCESS,
  payload
})

export const loadTickerStateFailure = (payload: string): LoadTickerStateFailureAction => ({
  type: LOAD_TICKER_STATE_FAILURE,
  payload
})

export const matchUpdate = (payload: TickerMatchState): MatchUpdateAction => ({
  type: MATCH_UPDATE,
  payload
})

export const matchStatsUpdate = (payload: TickerMatchStats): MatchStatsUpdateAction => ({
  type: MATCH_STATS_UPDATE,
  payload
})

export type AssociationTickerStateAction =
  | LoadAssociationTickerStateSuccessAction
  | LoadTickerStateFailureAction
  | MatchUpdateAction
  | MatchStatsUpdateAction

export const associationTickerStateReducer = (
  state: AssociationTickerState = initialAssociationTickerState,
  action: AssociationTickerStateAction): AssociationTickerState => {

  switch (action.type) {

    case LOAD_TICKER_STATE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: "Fehler beim laden der Daten, bitte versuchen Sie es erneut."
      }
    }

    case LOAD_ASSOCIATION_TICKER_STATE_SUCCESS: {
      const payload = action.payload
      const matchStatesByUuid = mapTickerMatchStates(payload.matchStates)

      return {
        ...state,
        matchSeries: payload.matchSeries,
        matchDays: payload.matchDays,
        settings: payload.settings,
        matchStatsByUuid: payload.matchStats,
        matchStatesByUuid,
        loading: false
      }
    }

    case MATCH_UPDATE: {
      const matchState = action.payload
      return {
        ...state,
        matchStatesByUuid: {
          ...state.matchStatesByUuid,
          [matchState.matchUuid]: {
            ...state.matchStatesByUuid[matchState.matchUuid],
            ...matchState,
            ...isDetailedTickerMatchState(matchState) ? matchState.eventHistory.reduce(eventReducer, undefined as any) : {}
          }
        }
      }
    }

    case MATCH_STATS_UPDATE: {
      const matchStats = action.payload
      return {
        ...state,
        matchStatsByUuid: {
          ...state.matchStatsByUuid,
          [matchStats.matchUuid]: {
            ...state.matchStatsByUuid[matchStats.matchUuid],
            ...matchStats
          }
        }
      }
    }

    default: {
      return state
    }

  }

}

const initialTeamSquad: EventTeamSquad = {
  liberos: [],
  officials: [],
  players: []
}

const initialTeamSquads: EventTeamSquads = {
  team1: initialTeamSquad,
  team2: initialTeamSquad
}

const teamSquadsReducer = (state: EventTeamSquads = initialTeamSquads, event: ConfirmTeamSquadEvent): EventTeamSquads => {
  return {
    ...state,
    [event.teamCode]: event.teamSquad
  }
}

const arbitrationReducer = (_: EventArbitration = {} as EventArbitration, event: ConfirmArbitrationEvent): EventArbitration => {
  return event.arbitration
}


const initialEventMatchState: EventMatchState = {
  teamSquads: teamSquadsReducer(undefined, {} as any),
  arbitration: arbitrationReducer(undefined, {} as any),
  matchConfig: {} as MatchConfiguration
}

// TODO MAKE TYPE SAFE!
export const eventReducer = (state: EventMatchState = initialEventMatchState, event: any): EventMatchState => {

  switch(event.type) {

    case 'INITIALIZE_MATCH':
      return {
        ...state,
        matchConfig: event.matchConfig
      }

    case 'CONFIRM_TEAMSQUAD':
      return {
        ...state,
        teamSquads: teamSquadsReducer(state.teamSquads, event)
      }
    case 'CONFIRM_ARBITRATION':
      return {
        ...state,
        arbitration: arbitrationReducer(state.arbitration, event)
      }

    default:
      return state

  }

}

export const isDetailedTickerMatchState = (state: TickerMatchState): state is TickerApiMatchState => {
  return !!(state as any).eventHistory
}
