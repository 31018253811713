import * as React from 'react';

import './MatchList.scss';
import { TickerMatchStatesByUuid } from '../../ticker-state/reducer';
import { MatchListItem } from './match-list-item/MatchListItem';
import { TickerMode } from '../../../../shared/models/ticker';
import { TickerMatchDay, TickerMatchSeriesByUuid } from '../../models';
import { sortMatches } from '../../utils';
import { useContext, useEffect } from 'react';
// @ts-ignore
import { SwipeableViewsContext } from 'react-swipeable-views';

interface Props {
  matchDay: TickerMatchDay
  matchStatesByUuid: TickerMatchStatesByUuid
  matchSeries: TickerMatchSeriesByUuid
  mode: TickerMode
}

export const MatchList = (props: Props) => {

  const context: any = useContext(SwipeableViewsContext);
  useEffect(() => {
    context.slideUpdateHeight()
  })

  const { matchSeries, mode, matchDay, matchStatesByUuid } = props
  const { matches } = matchDay
  const sorted = [...matches]
    .sort(sortMatches(matchStatesByUuid, matchSeries))
  const items = sorted
    .map((m, i) => <MatchListItem
      mode={mode}
      key={m.id}
      match={m}
      matchSeries={!sorted[i - 1] || (sorted[i - 1].matchSeries !== m.matchSeries) ? matchSeries[m.matchSeries] : undefined}
      matchState={props.matchStatesByUuid[m.id]} />
    )
  return <div className="Match-list">{items}</div>

}
