import * as React from 'react'
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FavoritesState, toggleFavorite } from './reducer';
import './FavouritesButton.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons'

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    toggleFavorite: (matchUuid: string) => dispatch(toggleFavorite(matchUuid))
  }
}

interface OwnProps {
  matchUuid: string
  favorites: FavoritesState
}

type Props = ReturnType<typeof mapDispatchToProps> & OwnProps

const FavouritesButton = React.memo((props: Props) => {
  const favored = props.favorites.indexOf(props.matchUuid) > -1
  return (
    <div className={'FavouritesButton' + (favored ? ' active' : '')}>
      <button onClick={props.toggleFavorite.bind(null, props.matchUuid)}>
        {favored ? <span><FontAwesomeIcon icon={faHeart} /> Aus Favoriten entfernen</span> : <span><FontAwesomeIcon icon={farHeart} />Zu meinen Favoriten</span>}
      </button>
    </div>
  )
})

export default connect(null, mapDispatchToProps)(FavouritesButton)
