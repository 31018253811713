import ReconnectingWebSocket, { OpenEvent, CloseEvent, MessageEvent } from 'reconnectingwebsocket';

import { config } from './config/config';
import {
  ServerSocketEvent,
  FetchAssociationTickerResponsePayload
} from '../../../ticker/shared/socket/events'
import {
  loadTickerStateFailure,
  loadAssociationTickerStateSuccess,
  TickerMatchStatesByUuid,
  matchUpdate,
  eventReducer,
  LoadAssociationTickerStatePayload,
  isDetailedTickerMatchState,
} from './ticker-state/reducer';
import {
  connectionOpened,
  connectionTerminated
} from './connection/reducer';
import { TickerStore, associationReducer } from './index';
import { EventMatchState, TickerMatch, TickerMatchState, TickerMatchDay } from './models';
import { formatDate, formatTime, getDayName, getToday } from './utils';
import { TickerApiMatch, TickerApiTeam } from '../../../ticker/shared/models/api';
import {
  TickerApiMatchStatesByUuid,
  TickerApiMatchState,
  TickerApiMatchDay,
  CachedTickerMatchSeriesByUuid,
  SimpleTickerApiMatchState
} from '../../../ticker/shared/models/ticker';
import { setActiveFilter } from './day-filter/reducer';
import { matchStatsUpdate } from './ticker-state/reducer';

const socketOptions: ReconnectingWebSocket.Options = { reconnectInterval: 3000, maxReconnectAttempts: 100, reconnectDecay: 2, timeoutInterval: 50000 }

export const connectToTicker = async (tickerId: string, store: TickerStore, teamId?: string) => {

  const onFetchAssociationTickerResponse = (payload: FetchAssociationTickerResponsePayload | null, tickerId: string) => {

    if (payload === null) {
      return store.dispatch(loadTickerStateFailure('No ticker data received for ticker instance: ' + tickerId))
    }

    const state: LoadAssociationTickerStatePayload = {
      matchDays: payload.matchDays.map(m => mapTickerMatchDay(m, payload.matchSeries)),
      matchSeries: payload.matchSeries,
      matchStates: mapTickerMatchStates(payload.matchStates),
      matchStats: payload.matchStats,
      settings: payload.settings
    }
    const rootState: ReturnType<typeof associationReducer> = store.getState() as any
    if (rootState.dayFilter.activeFilterDay === null) {
      const today = getToday()
      const todayIdx = payload.matchDays.findIndex(m =>
        new Date(m.date).toLocaleDateString() === today.toLocaleDateString())
      store.dispatch(setActiveFilter(todayIdx))
    }
    return store.dispatch(loadAssociationTickerStateSuccess(state))
  }

  const tickerUrl = `${config.wssEndpoint}/${tickerId}`
  const url = teamId ? tickerUrl + `/${teamId}` : tickerUrl

  try {
    const res = await fetch(`${config.httpEndpoint}/live/tickers/${tickerId}`)
    if (res.ok) {
      const payload: FetchAssociationTickerResponsePayload = await res.json()
      onFetchAssociationTickerResponse(payload, tickerId)
    } else {
      onFetchAssociationTickerResponse(null, tickerId)
    }
  } catch(error) {
    onFetchAssociationTickerResponse(null, tickerId)
  }

  const client = new ReconnectingWebSocket(url, undefined, socketOptions)

  const onMessage = (msg: MessageEvent) => {

    try {

      const event: ServerSocketEvent = JSON.parse(msg.data)

      switch (event.type) {
        case 'FETCH_DATA_RESPONSE_ERROR':
          const msg = event.payload.type === 'NOT_FOUND'
            ? `Es konnte kein Ticker mit der id ${tickerId} gefunden werden.`
            : `Bitte überprüfen Sie die tickerId: ${tickerId}`
          client.close()
          return store.dispatch(loadTickerStateFailure(msg))

        case 'MATCH_UPDATE':
          try {
            const matchstate = mapTickerMatchState(event.payload)
            return store.dispatch(matchUpdate(matchstate))
          } catch (error) {
            return
          }
        
        case 'MATCH_STATS_UPDATE':
          try {
            const matchstats = event.payload
            return store.dispatch(matchStatsUpdate(matchstats))
          } catch (error) {
            return
          }

        default:
          return null

      }

    } catch (err) {
      return store.dispatch(loadTickerStateFailure('Failed parsing ticker data for ticker instance: ' + tickerId))
    }
  }

  client.onopen = (event: OpenEvent) => store.dispatch(connectionOpened(event))
  client.onclose = (event: CloseEvent) => {
    store.dispatch(connectionTerminated())
  }
  client.onmessage = onMessage

}

export const mapTickerMatch = (match: TickerApiMatch, teams: TickerApiTeam[]): TickerMatch => {
  const matchDate = new Date(match.date)
  const team1 = teams.find(t => t.id === match.team1) as TickerApiTeam
  const team2 = teams.find(t => t.id === match.team2) as TickerApiTeam
  return {
    ...match,
    team1,
    team2,
    formattedDate: formatDate(matchDate),
    formattedTime: formatTime(matchDate)
  }
}

export const mapTickerMatchDay = (matchDay: TickerApiMatchDay, matchSeries: CachedTickerMatchSeriesByUuid): TickerMatchDay => {
  const matches = matchDay.matches.map(m => {
    const ms = matchSeries[m.matchSeries]
    if (!ms) {
      return null
    }
    return mapTickerMatch(m, ms.teams)
  }).filter(x => !!x) as TickerMatch[]
  return {
    matches,
    date: matchDay.date,
    formattedDate: getDayName(new Date(matchDay.date))
  }
}

export const mapTickerMatchStates = (matchStates: TickerApiMatchStatesByUuid): TickerMatchStatesByUuid => {
  return Object.keys(matchStates).reduce((result, matchUuid) => {
    const matchState = matchStates[matchUuid]
    return {
      ...result,
      [matchUuid]: mapTickerMatchState(matchState) 
    }
  }, {} as TickerMatchStatesByUuid)
}

export const mapTickerMatchState = (matchState: TickerApiMatchState | SimpleTickerApiMatchState): TickerMatchState => {
  if (!isDetailedTickerMatchState(matchState)) {
    return matchState
  } else {
    const eventMatchState: EventMatchState = [...matchState.eventHistory].reverse().reduce(eventReducer, eventReducer(undefined, {} as any))
    return {
      ...eventMatchState,
      ...matchState
    }
  }

}
