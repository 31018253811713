import * as React from "react";

import "./SideNav.scss";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  title: string
  position: string
  toggleIcon: JSX.Element
  onToggle: (visible: boolean) => void
  nav: JSX.Element
  visible: boolean
  hidden: boolean
}

class SideNav extends React.PureComponent<Props> {

  onToggle = () => {
    this.props.onToggle(this.props.visible);
  }

  render() {

    return (
      <div className={"SideNav" + (this.props.visible ? ' active' : '') + " SideNav-" + this.props.position}>
        <button style={{ "visibility": this.props.visible ? "hidden" : "visible" }}
          className="SideNav-showButton SideNav-button"
          aria-label="Open Sidenav"
          onClick={this.onToggle}
          hidden={this.props.hidden}>
            {this.props.toggleIcon}
        </button>
        <button style={{ "visibility": this.props.visible ? "visible" : "hidden" }}
          className="SideNav-closeButton SideNav-button"
          aria-label="Close Sidenav"
          onClick={this.onToggle}>
            <FontAwesomeIcon icon={faTimes}/>
        </button>
        <nav style={{ "visibility": this.props.visible ? "visible" : "hidden" }} className="SideNav-inner">
          <h1>{this.props.title}</h1>
          {this.props.nav}
        </nav>
      </div>
    )
  }

}

export default SideNav;
