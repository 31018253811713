import * as React from "react";

import './CurrentSetScore.scss';
import { TickerMatchState } from '../../../models';

interface Props {
  matchState: TickerMatchState
}

export const CurrentSetScore = React.memo((props: Props) => {
  const matchState = props.matchState
  if (!matchState) {
    return <div className="CurrentSetScore inactive">0:0</div>
  }
  const currentSet = matchState.matchSets[matchState.matchSets.length -1]
  return <div className={"CurrentSetScore" + (matchState.finished ? " finished" : "")}>
    {currentSet.setScore.team1 + ":" + currentSet.setScore.team2}
    </div>
})
