import * as React from 'react';

import './MatchFilter.scss';
import { connect } from 'react-redux';
import { setActiveFilter, setFocusedFilter } from '../day-filter/reducer';
import { Dispatch, bindActionCreators } from 'redux';
import DayFilter from '../day-filter/DayFilter';
import { TickerMatchDays } from '../models';
import { AssociationRootState } from '..';

export interface ScrollData {
  left: number
  clientWidth: number
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  setActiveFilter,
  setFocusedFilter
}, dispatch)

const mapStateToProps = (state: AssociationRootState, ownProps: OwnProps) => {
  return {
    activeFilterDay: state.dayFilter.activeFilterDay,
    visibilityFilterDays: ownProps.matchDays.map(matchDay => matchDay.formattedDate),
    focusedFilterDay: state.dayFilter.focusedFilterDay
  }
}

interface OwnProps {
  matchDays: TickerMatchDays
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & OwnProps


class MatchFilter extends React.PureComponent<Props> {

  private matchFilterRef = React.createRef<HTMLDivElement>()
  private initialScrollData: ScrollData
  private currentFilterScrollSelectTimeout: NodeJS.Timeout;

  componentDidMount() {
    this.scrollToDayFilter(this.initialScrollData)
  }

  scrollToDayFilter = (scrollData: ScrollData) => {
    const container = this.matchFilterRef.current
    if (!container || !scrollData) return
    const containerLeft = container.getBoundingClientRect().left
    const selectionLeft = scrollData.left - containerLeft
    container.scrollLeft += selectionLeft - container.clientWidth / 2 + scrollData.clientWidth / 2
    container.style.scrollBehavior = "smooth"
  }

  applyFilter = (filter: number, scrollData: ScrollData) => {
    if (!this.initialScrollData) {
      this.initialScrollData = scrollData
    }
    if (filter !== this.props.activeFilterDay) {
      this.props.setActiveFilter(filter)
    }
    this.scrollToDayFilter(scrollData)
  }


  applyScroll = (idx: number, scrollData: ScrollData) => {
    this.scrollToDayFilter(scrollData)
  }

  selectMatchFilterByElement = (a: HTMLDivElement, idx: number) => {
    if (this.props.activeFilterDay !== idx) {
      this.props.setActiveFilter(idx)
    }
  }

  onScroll = (ev: React.UIEvent) => {
    const innerWidth = window.innerWidth / 2
    const elems = ev.currentTarget.children[0].children
    for (let i = 0; i < elems.length; i++) {
      const a = elems[i] as any
      const rect = a.getBoundingClientRect()
      if (rect.left < innerWidth && rect.right > innerWidth) {
        if (this.currentFilterScrollSelectTimeout) {
          clearTimeout(this.currentFilterScrollSelectTimeout)
        }
        if (this.props.activeFilterDay !== i) {
          this.currentFilterScrollSelectTimeout = setTimeout(() => this.selectMatchFilterByElement(a, i), 100)
        }
      }
    }
  }

  public render() {

    const { visibilityFilterDays, activeFilterDay, focusedFilterDay } = this.props

    return (
      <div className="Match-filter" ref={this.matchFilterRef} onScroll={this.onScroll}>
        <div className="Match-filter-items">
          {visibilityFilterDays.map((d, idx) =>
            <DayFilter
              key={d}
              idx={idx}
              selected={activeFilterDay === idx}
              focused={focusedFilterDay === idx}
              formattedDay={d}
              applyFilter={this.applyFilter}
              applyScroll={this.applyScroll} />
          )}
        </div>
      </div>
    )
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(MatchFilter)
