import * as React from 'react'
import './ViewSelection.scss';
import { ViewSelectionState, changeView } from './reducer';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { faTh, faListUl } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AssociationRootState } from '../..';


const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  changeView,
}, dispatch)

const mapStateToProps = (state: AssociationRootState) => {
  return {
    selectedView: state.selectedView
  }
}

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>

const ViewSelection = React.memo((props: Props) => {
  const { selectedView } = props
  return (
    <div className="View-selection">
      <button onClick={props.changeView.bind(null, ViewSelectionState.Cards)}
        className={"Cards-view" + (selectedView === ViewSelectionState.Cards ? ' active' : '')}>
        <FontAwesomeIcon icon={faTh}/> Kachelansicht
      </button>
      <button onClick={props.changeView.bind(null, ViewSelectionState.List)}
        className={"List-view" + (selectedView === ViewSelectionState.List ? ' active' : '')}>
        <FontAwesomeIcon icon={faListUl}/> Listenansicht
      </button> 
    </div>
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewSelection)
