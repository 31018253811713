import {
  TickerApiMatchState,
  CachedTickerMatchSeriesByUuid,
  TickerApiMatchSet,
  TickerApiMatchStats,
  SimpleTickerApiMatchState
} from '../../../shared/models/ticker';
import { MatchConfiguration } from '../../../../shared/interfaces/models';
import { EventTeamSquads, EventArbitration } from '../../../../shared/event-api/model';
import { TickerApiTeam, TickerApiRanking } from '../../../shared/models/api';
import { CachedTickerMatchSeries } from '../../../shared/models/redis';

export interface TickerMatchDate {
  day: string
  time: string
}

export interface EventMatchState {
  matchConfig: MatchConfiguration
  teamSquads: EventTeamSquads
  arbitration: EventArbitration
}

export enum Status {
  PRE = "PRE",
  LIVE = "LIVE",
  POST = "POST"
}

/**
 * A matchstate combined from the TickerMatchState that gets sent with each event and
 * the EventMatchState which is build on the client as events arrive.
 */

export type DetailedTickerMatchState = (TickerApiMatchState & EventMatchState)
export type SimpleTickerMatchState = SimpleTickerApiMatchState
export type TickerMatchState = DetailedTickerMatchState | SimpleTickerMatchState

export type TickerMatchStats = TickerApiMatchStats

export interface TickerMatch {
  id: string
  teamDescription1: string
  team1: TickerApiTeam
  teamDescription2: string
  team2: TickerApiTeam
  date: number
  matchSeries: string
  indefinitelyRescheduled: boolean
  delayPossible: boolean
  formattedDate: string
  formattedTime: string
}

export type TickerTeam = TickerApiTeam
export type TickerMatchSet = TickerApiMatchSet
export type TickerRanking = TickerApiRanking

export type TickerMatchSeries = CachedTickerMatchSeries

export interface TickerMatchDay {
  matches: TickerMatch[]
  date: string
  formattedDate: string
}

export type TickerMatchDays = TickerMatchDay[]
export type TickerMatchSeriesByUuid = CachedTickerMatchSeriesByUuid
