import * as React from 'react';

import './LiveReferees.scss'
import { RefereeCard } from './RefereeCard';
import { EventArbitration } from '../../../../../../shared/event-api/model';
import { TickerMatch } from '../../../models';

interface Props {
  arbitration: EventArbitration
  match: TickerMatch
}

export const LiveReferees = (props: Props) => {

  const { arbitration } = props
  const {
    firstReferee,
    secondReferee,
    challengeReferee,
    scorer,
    scorerAssistant,
    firstLinesman,
    secondLinesman,
    thirdLinesman,
    fourthLinesman,
    supervisor
  } = arbitration
  return <div className='LiveReferees'>
    <RefereeCard arbitrationMember={firstReferee} type="1. Schiedsrichter" />
    <RefereeCard arbitrationMember={secondReferee} type="2. Schiedsrichter" />
    {challengeReferee && <RefereeCard arbitrationMember={challengeReferee} type="Challenge Schiedsrichter" />}
    {firstLinesman && <RefereeCard arbitrationMember={firstLinesman} type="1. Linienrichter" />}
    {secondLinesman && <RefereeCard arbitrationMember={secondLinesman} type="2. Linienrichter" />}
    {thirdLinesman && <RefereeCard arbitrationMember={thirdLinesman} type="3.Linienrichter" />}
    {fourthLinesman && <RefereeCard arbitrationMember={fourthLinesman} type="4. Linienrichter" />}
    <RefereeCard arbitrationMember={scorer} type="Schreiber" />
    <RefereeCard arbitrationMember={scorerAssistant} type="Schreiberassistent" />
    {supervisor && <RefereeCard arbitrationMember={supervisor} type="Supervisor" />}
  </div>
}
