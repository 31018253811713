import * as React from "react";

import './MatchCardHeader.scss';
import { TickerMatchState } from '../../../models';
import { MatchFooter } from './MatchFooter';
import { TickerMode } from '../../../../../shared/models/ticker';
import { Status } from '../../../models/index';
import { FavoritesState } from '../../../match/favourite/reducer';

interface Props {
  matchState: TickerMatchState
  mode: TickerMode
  matchUuid: string
  scoreSheetPdfUrl: string | undefined
  status: Status
  favorites?: FavoritesState
}


export const MatchCardFooter = (props: Props) => {

  const {
    mode,
    matchUuid,
    matchState,
    scoreSheetPdfUrl,
    status,
    favorites
  } = props

  return (
    <div className="MatchCardFooter">
      <MatchFooter favorites={favorites} mode={mode} status={status} matchUuid={matchUuid} matchState={matchState} scoreSheetPdfUrl={scoreSheetPdfUrl} />
    </div>
  )
}
