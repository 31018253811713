import * as React from 'react';

import './Rankings.scss'
import { RankingCard } from './RankingCard';
import { TickerMatchSeries } from '../../../models';

interface Props {
  matchSeries: TickerMatchSeries
}

export const Rankings = (props: Props) => {

  const matchSeries = props.matchSeries
  if (!matchSeries.rankings) {
    return <div></div>
  }
  const rankings = matchSeries.rankings.fullRankings
  return <div>
    <div className='Rankings tile tile-shadow'>
      <div className='table'>
        <div className='header'>
          <div>Platz</div>
          <div />
          <div>Verein</div>
          <div>Spiele</div>
          <div>Punkte</div>
        </div>
        {rankings.map(ranking => <RankingCard key={ranking.team.id} ranking={ranking} />)}
      </div>
    </div>
  </div>

}
