import * as React from "react";

import './MatchListItem.scss';
import { MatchInfo } from './MatchInfo';
import { TeamLogo } from "../../../match/TeamLogo";
import { TickerMatchState, TickerMatch, TickerMatchSeries } from '../../../models';
import { getMatchDetailPath } from '../../../utils';
import { NavLink } from 'react-router-dom';
import { TickerMode } from "../../../../../shared/models/ticker";

interface Props {
  matchState?: TickerMatchState
  match: TickerMatch
  matchSeries: TickerMatchSeries | undefined
  mode: TickerMode
}

export const MatchListItem = (props: Props) => {
  const {
    match,
    matchState,
    matchSeries,
    mode
  } = props

  const { team1, team2, indefinitelyRescheduled, delayPossible } = match

  return (
    <div className={"Match-list-item" + (matchState?.finished ? " finished" : "")}>
      {matchSeries &&
        <div className={"Match-list-match-series" + (indefinitelyRescheduled ? ' rescheduled' : '')}>
          <h3 className="Match-series-name">{matchSeries.name}</h3>
        </div>
      }
      <div className="Match-list-item-team1 Match-list-item-team">
        {team1 ? <TeamLogo rescheduled={indefinitelyRescheduled} side={"leftTeam"} team={team1} /> : match.teamDescription1}
      </div>
      <div className="Match-list-item-match-info">
        {matchState && matchState.ready && mode === 'DETAILED' ?
          <NavLink
            key={match.id}
            to={getMatchDetailPath(match)}
            className="Match-detail-link">
            <MatchInfo matchState={matchState} match={match} />
          </NavLink>
          : <MatchInfo matchState={matchState} match={match} />}
        {indefinitelyRescheduled && <div className="rescheduled-info">Termin folgt.</div>}
        {(delayPossible && !indefinitelyRescheduled && !matchState?.started) && <div className="delay-info">verzögerter Spielbeginn möglich</div>}
      </div>
      <div className="Match-list-item-team2 Match-list-item-team">
        {team2 ? <TeamLogo rescheduled={indefinitelyRescheduled} side={"rightTeam"} team={team2} /> : match.teamDescription2}
      </div>
    </div>
  )
}
