import * as React from 'react';

import './Avatar.scss'

interface Props {
  placeholder: string
  image?: string
}

export const Avatar = (props: Props) => {

  const {image, placeholder} = props

  return <div className="Avatar">
    <div className="Avatar-content" style={{ backgroundImage: null != image ? `url(${image})` : 'none'}}>
      {null == image ? placeholder.toUpperCase() : '' }
    </div>
  </div>

}
