import * as React from "react";
import { EventPlayer } from "../../../../../../../shared/event-api/model";

interface Props {
  player: EventPlayer
  isLibero?: boolean
}

export const PlayerListing = (props: Props) => {
  const { player, isLibero } = props
  return <li>{player.jerseyNumber}, {player.firstName} {player.lastName}{isLibero ? ', L' : ''}</li>
}
