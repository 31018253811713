import * as React from 'react';
import './MatchDetailHeader.scss'
import { MatchCardHeader } from '../match-cards/match-card/MatchCardHeader';
import { MatchCardBody } from '../match-cards/match-card/MatchCardBody';
import { TickerMatchState, TickerMatch, TickerMatchSeries } from '../../models';

import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { MatchInfoHeader } from './MatchInfoHeader';
import MatchDetailMenu from './detail-view/MatchDetailMenu';
import { MatchDetailViewInstance } from './MatchDetail';

interface OwnProps {
  match: TickerMatch
  matchState: TickerMatchState
  matchSeries: TickerMatchSeries
  matchDetailViews: MatchDetailViewInstance[]
  isDesktopView: boolean
}

const MatchDetailHeader = (props: OwnProps) => {


  const [isFixed, setIsFixed] = React.useState(false)
  const ref = React.useRef() as React.MutableRefObject<HTMLDivElement>
  const infoRef = React.useRef() as React.MutableRefObject<HTMLDivElement>
  const [margin, setMargin] = React.useState(0)

  React.useEffect(() => {

    let scrollTop = document.documentElement.scrollTop
    const offset = infoRef.current!.clientHeight || 0
    const threshold = ref.current!.clientHeight - offset

    const shrinkHeader = (e: any) => {
      scrollTop = e.target.documentElement.scrollTop

      if (scrollTop <= threshold) {
        setIsFixed(false)
        setMargin(0)
      } else {
        setIsFixed(true)
        setMargin(threshold)
      }
    }
    window.addEventListener('scroll', shrinkHeader);

    return () => window.removeEventListener('scroll', shrinkHeader)

  }, [isFixed, ref])

  const { match, matchState, matchSeries, matchDetailViews } = props

  return <div className={'MatchDetailHeader ' + (isFixed ? 'header-fixed' : '')} style={{ "marginBottom": margin }}>
    {<div className="inner" style={{ "visibility": isFixed ? "hidden" : "visible", "position": isFixed ? "absolute" : "relative" }} ref={ref}>
      <div className="backlink"><NavLink className="backlink" to={"/"}>&lt; Zurück zur Übersicht</NavLink></div>
      <MatchCardHeader match={match} matchSeries={matchSeries} matchState={matchState} />
      <MatchCardBody match={match} matchState={matchState} />
      <MatchDetailMenu matchDetailViews={matchDetailViews} liveStatisticsEnabled={matchState.liveStatisticsEnabled} matchSeries={matchSeries}></MatchDetailMenu>
    </div>}
    {<div style={{ "visibility": !isFixed ? "hidden" : "visible", "position": !isFixed ? "absolute" : "relative" }} ref={infoRef}><MatchInfoHeader match={match} matchState={matchState}/></div>}

  </div>

}

export default connect()(MatchDetailHeader)
